@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Inter;
    scroll-behavior: smooth;
}

#root {
    width: 100%;
    height: 100%;
    background-color: "#000";
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


input::-webkit-search-cancel-button{
     /* Remove default */
  -webkit-appearance: none;
  /*Your new styles */
}

/* color change for scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  background-color: transparent !important; /* Set to the same color as the background */
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent !important; /* Set to the same color as the background */
  }

  ::-moz-scrollbar {
    background-color: transparent !important; /* Set to the same color as the background */
  }

  ::-moz-scrollbar-thumb {
    background-color: transparent !important; /* Set to the same color as the background */
  }
.spinner-page {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    .loader {
        border: 4px solid #f3f3f3;
        border-radius: 50%;
        border-top: 4px solid #3498db;
        width: 60px;
        height: 60px;
        -webkit-animation: spin 0.5s linear infinite; /* Safari */
        animation: spin 0.5s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

.loader{
    margin: 0 0 2em;
    height: 100px;
    width: 0%;
    text-align: center;
    padding: 1em;
    margin: 0 auto 1em;
    display: inline-block;
    vertical-align: top;
  }

  /*
    Set the color of the icon
  */
//   svg path,
//   svg rect{
//     fill: #FF6700;
//   }